import * as React from "react";
import { useEffect, useState } from "react";
import { AuthProps, TokenModel } from "../../models/token";
import useAuth from "../../hooks/auth";
import { RouteComponentProps } from "@reach/router";
import loadable from "@loadable/component";

const PageLoading = loadable(
  () => import(/* webpackChunkName:"page-loading" */ "./pageLoading")
);
const LoginPage = loadable(
  () => import(/* webpackChunkName:"login" */ "../../templates/pages/login")
);

export interface PrivateComponentProps extends RouteComponentProps {
  component?: React.FC<AuthProps>;
}

const PrivateComponent: React.FC<PrivateComponentProps> = ({
  path,
  component,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [auth, setAuth] = useState<TokenModel>();

  const PageComponent = component;

  useEffect(() => {
    const [authData, errAuth] = useAuth();

    if (!errAuth) {
      setAuth(authData);
    }

    setIsReady(true);
  }, []);

  if (!isReady) return <PageLoading />;

  if (!auth) return <LoginPage />;

  return <>{component && <PageComponent path={path} auth={auth} />}</>;
};

export default PrivateComponent;
